.contact-container {
  /* height: 400px; */
  background: #1a1a1a;
  color: white;
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding: 60px 0;
}

.contact-container h1 {
  text-align: center;
  margin-bottom: 40px;
}

.contact-icon-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.contact-icon-wrapper span {
  margin: 0 15px;
}

.contact-icon-wrapper img {
  width: 35px;
  height: auto;
  margin-right: 6px;
}

.contact-icon-wrapper a {
  color: white;
  text-decoration: none;
}

.contact-copyright {
  text-align: center;
  font-size: 13pt;
}

.contact-social-icon {
  width: 25px;
  height: auto;
}
