.about-us_container {
  /* background: url("http://getwallpapers.com/wallpaper/full/a/d/1/266509.jpg"); */
  background: center no-repeat url("../../assets/images/SLIDE2.jpg");
  background-size: cover;
}

.about-us_header {
  position: relative;
  background: rgba(255, 255, 255, 0.6);
  width: 345px;
  left: 20px;
  top: 20px;
  padding: 10px 20px;
}

.about-us_header_text {
  font-size: 4rem;
  color: black;
  font-weight: 300;
}

.about-us_content_container {
  /* display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8.3rem;
  margin-right: 2rem; */

  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4.3rem;
  padding-bottom: 9rem;
}

.about-us_content {
  /* height: 34rem;
  background: rgba(126, 192, 231, 0.733);
  width: 38rem;
  padding: 35px;
  text-align: justify;
  line-height: 2;
  margin-left: auto;
  margin-right: auto;
  line-height: 2.5rem;
  font-size: 1.3rem; */

  width: 64rem;
  padding: 80px 120px;
  text-align: justify;
  margin-left: auto;
  margin-right: auto;
  background: #e6e6e6;
  border: 8px solid white;
  border-radius: 53px;
}

.about-us_content p {
  font-size: 20pt;
}

@media (max-width: 1072px) {
  .about-us_content {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
}

@media (max-width: 700px) {
  .about-us_content {
    padding: 20px;
  }
}

@media (max-width: 450px) {
  .about-us_header_text {
    font-size: 3rem;
  }
  .about-us_content p {
    font-size: 16pt;
  }
  .about-us_content_container {
    padding-bottom: 3rem;
  }
}
