.hero_container {
  height: 100vh;
  overflow: hidden;
}

.hero_content_container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.hero_content {
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-bottom: 15px;
  color: white;
}

.hero_lower_content {
  color: white;
  display: flex;
  justify-content: space-evenly;
  border-top: 1px solid white;
  margin: 0 25%;
  padding: 15px 0 0 0;
}

#hero_video {
  min-height: 100%;
}

.hero_content_wrapper {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.hero_titles {
  text-align: center;
  font-size: 13pt;
}

.hero_content h1 {
  font-size: 4rem;
  text-align: center;
}

.hero_vertical-line {
  padding: 0 10px;
}

@media (max-width: 500px) {
  .hero_content h1 {
    font-size: 3rem;
    letter-spacing: 3px;
  }
}

@media (max-width: 1200px) {
  .hero_lower_content {
    margin: 0 10%;
  }
}

@media (max-width: 450px) {
  .hero_titles {
    font-size: 12pt;
  }
}

@media only screen and (min-width: 1920px) {
  #hero_video {
    width: 100%;
  }
}
