.light-blue {
  color: #0089b3;
}

.dark-blue {
  color: #003954;
}

.blue-radial {
  background: rgb(0, 137, 179);
  background: radial-gradient(
    farthest-corner at 50% 50%,
    rgba(0, 137, 179, 1) 0%,
    rgba(0, 57, 84, 1) 75%
  );
}

.green-radial {
  background: rgb(0, 168, 151);
  background: radial-gradient(
    farthest-corner at 50% 50%,
    rgba(0, 168, 151, 1) 0%,
    rgba(0, 130, 108, 1) 75%
  );
}

.red-radial {
  background: rgb(252, 192, 128);
  background: radial-gradient(
    farthest-corner at 50% 50%,
    rgba(252, 192, 128, 1) 0%,
    rgba(186, 81, 101, 1) 75%
  );
}

.yellow-radial {
  background: rgb(253, 230, 209);
  background: radial-gradient(
    farthest-corner at 50% 50%,
    rgba(253, 230, 209, 1) 0%,
    rgba(255, 211, 155, 1) 75%
  );
}

.section-container {
  height: 87vh;
  width: 100%;
}

.carousel {
  height: 87vh;
  overflow: hidden;
}

.sources-container {
    padding: 20px;
    color: white;
}

.blue-1-img-bg {
  background-image: url("../../../assets/images/mobile/BLUE-1-BG.jpg"); /* The image used */
  background-color: #cccccc; /* Used if the image is unavailable */
  height: 88vh; /* You must set a specified height */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}

.green-1-img-bg {
  background-image: url("../../../assets/images/mobile/GREEN-1-BG.jpg"); /* The image used */
  background-color: #cccccc; /* Used if the image is unavailable */
  height: 88vh; /* You must set a specified height */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}

.green-2-img-bg {
  background-image: url("../../../assets/images/mobile/GREEN-2-BG.jpg"); /* The image used */
  background-color: #cccccc; /* Used if the image is unavailable */
  height: 88vh; /* You must set a specified height */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}

.red-1-img-bg {
  background-image: url("../../../assets/images/mobile/RED-1-BG.jpg"); /* The image used */
  background-color: #cccccc; /* Used if the image is unavailable */
  height: 88vh; /* You must set a specified height */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}

.red-2-img-bg {
  background-image: url("../../../assets/images/mobile/RED-2-BG.jpg"); /* The image used */
  background-color: #cccccc; /* Used if the image is unavailable */
  height: 88vh; /* You must set a specified height */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}

.yellow-1-img-bg {
  background-image: url("../../../assets/images/mobile/YELLOW-1-BG.jpg"); /* The image used */
  background-color: #cccccc; /* Used if the image is unavailable */
  height: 88vh; /* You must set a specified height */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}

.yellow-2-img-bg {
  background-image: url("../../../assets/images/mobile/YELLOW-2.jpg"); /* The image used */
  background-color: #cccccc; /* Used if the image is unavailable */
  height: 88vh; /* You must set a specified height */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}

.yellow-3-img-bg {
  background-image: url("../../../assets/images/mobile/YELLOW-3.jpg"); /* The image used */
  background-color: #cccccc; /* Used if the image is unavailable */
  height: 87vh; /* You must set a specified height */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}

.yellow-4-img-bg {
  background-image: url("../../../assets/images/mobile/YELLOW-4-BG.jpg"); /* The image used */
  background-color: #cccccc; /* Used if the image is unavailable */
  height: 87vh; /* You must set a specified height */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}
