.fundManagement_container {
  padding-bottom: 3rem;
  background: center url("../../assets/images/SLIDE4.jpg");
}

.fundManagement_header_container {
  background: rgb(211, 211, 211);
}
.fundManagement_header_container h1 {
  padding: 15px;
  font-weight: 300;
  font-size: 4rem;
}

.fundManagement_header_content_wrapper {
  padding: 5px 40px 40px 40px;
}
.fundManagement_header_content_wrapper p {
  /* line-height: 2; */
}

.fundManagement_content_container {
  display: flex;
  justify-content: center;
  align-content: center;
  margin: 5rem 10rem 0 10rem;
}

.fundManagement_lower-content_wrapper {
  background: white;
  padding: 15px 35px;
  /* margin-bottom: 5rem; */
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  width: 45%;
  min-height: 385px;
  margin-left: 10px;
}
.fundManagement_lower-content_wrapper_swif {
  background: white;
  padding: 15px 35px;
  /* margin-bottom: 2rem; */
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  width: 45%;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  min-height: 385px;
  margin-right: 10px;
}

.fundManagement_lower-content_wrapper h2 {
  text-align: center;
}

.fundManagement_lower-content_wrapper p {
  text-align: justify;
}

.fundManagement_lower-content_wrapper_swif p {
  text-align: justify;
}

.fundManagement_button-container {
  display: flex;
  justify-content: center;
}

.fundManagement_button {
  border: 1px solid gray;
  border-radius: 5px;
  padding: 3px 40px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  text-transform: uppercase;
}

.fundManagement_button:hover {
  cursor: pointer;
  background: rgb(238, 238, 238);
}

.swif_logo {
  text-align: center;
  margin-bottom: 15px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swif_logo img {
  width: 200px;
}

@media (max-width: 1500px) {
  .fundManagement_lower-content_wrapper_swif {
    width: 100%;
    min-height: 320px;
    margin-bottom: 1.5rem;
  }
  .fundManagement_lower-content_wrapper {
    width: 100%;
    min-height: 320px;
    margin-left: 0;
  }
  .fundManagement_content_container {
    flex-flow: column;
  }
}

@media (max-width: 1030px) {
  .fundManagement_content_container {
    margin: 5rem 1.5rem 0 1.5rem;
  }
}

@media (max-width: 450px) {
  .fundManagement_header_container h1 {
    font-size: 3rem;
  }
  .fundManagement_lower-content_wrapper_swif p {
    text-align: left;
  }
  .fundManagement_lower-content_wrapper p {
    text-align: left;
  }
  .fundManagement_header_content_wrapper {
    padding: 5px 0 40px 0;
    margin: 0 1.5rem 0 1.5rem;
  }
  .fundManagement_lower-content_wrapper_swif {
    margin-right: 0;
    padding: 15px 20px;
  }
  .fundManagement_lower-content_wrapper {
    padding: 15px 20px;
  }
}
