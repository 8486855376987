.di-hero-container {
  margin-top: 110px;
  max-width: 900px;
  margin: auto;
  padding: 120px 20px 40px 20px;
}

.di-hero-container h1,
h4 {
  text-align: center;
}

.di-hero-container p {
  /* padding: 0 60px; */
}

.di-hero-image-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.di-hero-image-container img {
  width: 100%;
  padding: 20px 0;
}

@media only screen and (max-width: 910px) {
  /* .di-hero-container {
    padding: 20px 40px;
  } */
  .di-hero-container h1 {
    font-size: 2.2rem;
  }
}
