
.nav_clear {
  background-color: rgba(0, 0, 0, 0) !important;
  z-index: 101;
  box-shadow: none !important;
  transition: background-color 0.5s ease 0s;
  -webkit-transition: background-color 0.5s ease 0s;
  -moz-transition: background-color 0.5s ease 0s;
  -ms-transition: background-color 0.5s ease 0s;
  -o-transition: background-color 0.5s ease 0s;
}

.nav_color {
  background-color: #1a1a1a !important;
  z-index: 101;
  transition: background-color 0.5s ease 0s;
  -webkit-transition: background-color 0.5s ease 0s;
  -moz-transition: background-color 0.5s ease 0s;
  -ms-transition: background-color 0.5s ease 0s;
  -o-transition: background-color 0.5s ease 0s;
}

.nav_link_white {
  color: white !important;
  transition: background-color 0.5s ease 0s;
  -webkit-transition: background-color 0.5s ease 0s;
  -moz-transition: background-color 0.5s ease 0s;
  -ms-transition: background-color 0.5s ease 0s;
  -o-transition: background-color 0.5s ease 0s;
}

/* .nav_link_blue {
  color: #142d53 !important;
  transition: background-color 0.5s ease 0s;
  -webkit-transition: background-color 0.5s ease 0s;
  -moz-transition: background-color 0.5s ease 0s;
  -ms-transition: background-color 0.5s ease 0s;
  -o-transition: background-color 0.5s ease 0s;
} */

.nav-img:hover {
  cursor: pointer;
}
