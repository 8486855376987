.investPhil_container {
  /* height: 100vh; */
}

.investPhil_hero_img {
  width: 100%;
}

.investPhil_image_container {
  /* height: 400px; */
  /* overflow: hidden; */
}

.investPhil_content_wrapper {
  display: flex;
  margin: 0 15%;
}

.investPhil_leftside_content {
  padding: 45px;
}
.investPhil_leftside_content h1 {
  font-size: 4rem;
  font-weight: 300;
}

.investPhil_rightside_content {
  padding: 45px 0;
}

@media (max-width: 1450px) {
  .investPhil_content_wrapper {
    margin: 0 5%;
  }
}

@media (max-width: 1000px) {
  .investPhil_content_wrapper {
    flex-flow: column;
  }
  .investPhil_leftside_content {
    padding: 45px 45px 0 0;
  }
}

@media (max-width: 450px) {
  .investPhil_leftside_content h1 {
    font-size: 3rem;
  }
  .investPhil_rightside_content {
    padding: 20px 0;
  }
}
