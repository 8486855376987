html {
  scroll-behavior: smooth;
}

.uppercase {
  text-transform: uppercase;
}

.letter_spacing {
  letter-spacing: 8px;
  text-shadow: 1px 1px 4px #616161;
}

.text_center {
  text-align: center;
}

.margin_bottom {
  margin: 2rem 0;
}

.responsive_img {
  width: 100%;
  height: auto;
  float: left;
}

.responsive_img:after {
  content:"Hello"
}

a.nav-link {
  color: white !important;
}

p {
  font-size: 15pt;
  line-height: 150%;
  font-weight: 200;
}

.navbar {
  padding: 0 1.5rem;
}

.navbar-light .navbar-toggler {
  color: white;
  border-color: white;
}

.navbar-collapse {
  padding: 15px 0;
}

@media only screen and (min-width: 992px) {
  .navbar {
    height: 80px
  }
}


